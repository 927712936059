import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import routes from "./constants/routes";
import styles from './styles/App.scss';
import Header from "./components/Header/Header";
import ScrollToTopOnLoad from "./utils/scrollToTopOnLoad";
import Loader from "./components/Loader/Loader";

const Home = lazy(() => import("./pages/Home/Home"));
const Portfolio = lazy(() => import("./pages/Portfolio/Portfolio"));
const PortfolioCase = lazy(() => import("./pages/Portfolio/Cases/PortfolioCase"));
const Services = lazy(() => import("./pages/Services/Services"));
const WebsiteDevService = lazy(() => import("./pages/Services/Types/WebsiteDevService"));
const Contacts = lazy(() => import("./pages/Contacts/Contacts"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));

function App() {
  
  return (
    <div className={styles.App}>
      <BrowserRouter>
      <ScrollToTopOnLoad />
        <Header />
        <Suspense fallback={<Loader />}>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.PORTFOLIO}>
            <Route index element={<Portfolio />} /> 
            {['estate', 'dance', 'restaurant', 'building', 'wedding', 'carservice', 'trendavenue',
             'travelsense', 'childholidays', 'flowerplace', 'yoga'].map(path => <Route key={path} path={path} element={<PortfolioCase />} />)}
          </Route>
          <Route path={routes.SERVICES}>
            <Route index element={<Services />} />
            {['websitedev', 'softwaredev', 'webdesign', 'storedev', 'support', 'socialmedia', 'mobiledev', 'seo'].map(path => <Route key={path} path={path} element={<WebsiteDevService />} />)}
          </Route>
          <Route path={routes.CONTACTS} element={<Contacts />} />
        </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
